.App {
  width: 100vw;
  min-height: 100vh;
  text-align: center;
  background-color: rgb(2, 1, 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

/* cover */
.cover-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin-bottom: 0rem;
}
.cover-wrapper img {
  width: 100vw;
}
.cover-button {
  position: relative;
  bottom: calc(8rem - 2vmin);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 3px solid rgba(255, 255, 255, 0.9);
  border-radius: 2.5rem;
  margin: 0 auto;
  transform: scale(1.05);
  box-shadow: 0px 0px 25px rgb(230, 160, 175);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}
.cover-button:hover {
  cursor: pointer;
}
.cover-button:active {
  cursor: pointer;
  transform: scale(1.02);
}
.cover-button p {
  color: rgba(255, 255, 255, 0.9);
  font-size: calc(0.5rem + 2vmin);
  font-weight: 800;
  padding: 0vw 5vw 0vw 5vw;
  margin: 0.5rem 0;
}
@media screen and (min-width: 1000px) {
  .cover-button {
    position: relative;
    bottom: 18vw;
    transform: scale(1.05);
  }
  .cover-button:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
  .cover-button:active {
    cursor: pointer;
    transform: scale(1.02);
  }
  .cover-button p {
    margin: 1rem 0;
  }
}
/* address */
.address-wrapper input {
  width: 50vw;
  height: 3.5vw;
  text-align: center;
  font-size: calc(5px + 2vmin);
  letter-spacing: 0.75px;
  background-color: rgba(85, 85, 85, 0.1);
  border: 0px;
  border-radius: 50px;
  box-shadow: 0px 7.5px 50px rgb(230, 160, 175);
  padding: 0.1rem 0rem;
}
.address-wrapper input::placeholder {
  color: #fff;
  font-weight: 500;
}

/* intro-about-group */
.intro-about-group {
  display: flex;
  flex-direction: column;
}

/* inrto */
.introList-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem auto;
  padding: 0 0;
}
.introList-wrapper li {
  list-style: none;
  margin: 0.5rem 0 0.5rem 0;
}
.card-wrapper {
  background-color: rgb(15, 20, 25);
  border-radius: 0.5rem;
  padding-bottom: 2rem;
  padding-top: 0.5rem;
  width: 85vw;
  transition: cubic-bezier(0.215, 0.61, 0.355, 1) 0.75s;
}
.card-wrapper:active {
  transform: scale(1.03);
}
.card-icon {
  transform: translateY(1.5rem) scale(1.5);
}
.card-title {
  transform: translateY(1.2rem);
  color: rgb(230, 160, 175);
  font-size: calc(0.6rem + 2vmin);
  font-weight: 600;
}
.card-text {
  transform: translateY(1.2rem);
  font-size: calc(0.55rem + 2vmin);
}

/* About */
.about-wrapper {
  width: 85vw;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
}
.about-title {
  color: rgb(230, 160, 175);
}
.about-title .t1 {
  font-size: calc(0.8rem + 2vmin);
}
.about-title .t2 {
  margin: 1rem 0 1rem 0;
  font-size: calc(1.3rem + 2vmin);
  font-weight: 700;
}
.about-title .t2::after {
  color: rgb(230, 160, 175);
  content: "——";
  font-weight: 200;
}
.about-text p {
  font-size: calc(0.5rem + 2vmin);
  letter-spacing: 0.5px;
}
.about-avatar {
  margin: 2.5rem 0 0.5rem 0;
}
.about-avatar img {
  width: 38vw;
}

@media screen and (min-width: 1000px) {
  .intro-about-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;
    margin-bottom: 5rem;
  }
  .introList-wrapper {
    display: flex;
    align-items: center;
    width: 50vw;
  }
  .introList-wrapper .card-wrapper {
    width: 43vw;
  }
  .card-text {
    transform: translateY(1.2rem);
    font-size: calc(0.1rem + 2vmin);
  }
  .about-wrapper {
    width: 50vw;
    align-items: center;
  }
  .about-text {
    width: 40vw;
    padding: 0rem 1.5rem;
  }
  .about-text p {
    font-size: calc(0.3rem + 2vmin);
    font-weight: 300;
    letter-spacing: 0.5px;
  }
  .about-avatar img {
    width: 28vw;
  }
}

/* Advantage */
.advantage-wrapper {
  width: 85vw;
  margin: 1rem auto;
}
.advantage-title {
  color: rgb(230, 160, 175);
}
.advantage-title .t1 {
  font-size: calc(0.8rem + 2vmin);
}
.advantage-title .t2 {
  margin: 1rem 0 1rem 0;
  font-size: calc(1.3rem + 2vmin);
  font-weight: 700;
}
.advantage-title .t2::after {
  color: rgb(230, 160, 175);
  content: "——";
  font-weight: 200;
}
.advantage-text {
  font-size: calc(0.4rem + 2vmin);
  letter-spacing: 0.25px;
}
.advList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem auto;
  padding: 0 0;
}
.advList li {
  list-style: none;
  margin: 0.5rem 0 0.5rem 0;
}
.advList li .card-text {
  width: 78vw;
  margin: 0 auto;
}
@media screen and (min-width: 1000px) {
  .advList {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 3rem;
  }
  .advList .card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40vw;
    height: 28vh;
  }
  .advList .card-wrapper .card-text {
    width: 35vw;
  }
}

/* Farm */
.farm-wrapper {
  width: 85vw;
  margin: 1rem auto;
}
.farm-title {
  color: rgb(230, 160, 175);
}
.farm-title .t1 {
  font-size: calc(0.8rem + 2vmin);
}
.farm-title .t2 {
  margin: 1rem 0 1rem 0;
  font-size: calc(1.3rem + 2vmin);
  font-weight: 700;
}
.farm-title .t2::after {
  color: rgb(230, 160, 175);
  content: "——";
  font-weight: 200;
}
.farm-text {
  font-size: calc(0.5rem + 2vmin);
  letter-spacing: 0.25px;
}
.farm-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 3px solid rgb(230, 160, 175);
  border-radius: 2rem;
  background-color: #000;
  height: 4.5vh;
  width: 30vw;
  margin: 2rem auto 2.5rem auto;
  transform: scale(1.05);
  box-shadow: 0px 0px 30px rgb(230, 160, 175);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}
.farm-button:active {
  cursor: pointer;
  box-shadow: 0px 0px 25px rgb(230, 160, 175);
}
.farm-button a {
  color: rgb(230, 160, 175);
  font-size: calc(0.5rem + 2vmin);
  font-weight: 700;
}
.farm-image {
  margin: 2rem auto;
}
.farm-image img {
  width: 85vw;
}
@media screen and (min-width: 1000px) {
  .farm-wrapper {
    margin-top: 2rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .farm-wrapper .farm-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1rem;
  }
  .farm-wrapper .farm-content .farm-text {
    font-size: calc(0.25rem + 2vmin);
    font-weight: 300;
    width: 90%;
  }
  .farm-wrapper .farm-image {
    padding-top: 2.5rem;
    margin-left: 1rem;
    width: 40vw;
  }
  .farm-wrapper .farm-image img {
    width: 40vw;
  }
}

/* Roadmap */
.roadmap-wrapper {
  width: 80vw;
  margin: 1rem auto;
}
.roadmap-title {
  color: rgb(230, 160, 175);
}
.roadmap-title .t1 {
  font-size: calc(0.8rem + 2vmin);
}
.roadmap-title .t2 {
  margin: 1rem 0 1rem 0;
  font-size: calc(1.3rem + 2vmin);
  font-weight: 700;
}
.roadmap-title .t2::after {
  color: rgb(230, 160, 175);
  content: "——";
  font-weight: 200;
}
.noteList {
  padding: 0 0;
}
.noteList li {
  list-style: none;
  margin: 0.5rem 0;
}
.note-wrapper {
  background-color: rgb(33, 36, 37);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 7px;
  height: 5rem;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.75s;
}
.note-wrapper:active {
  transform: scale(1.02);
}
.note-avatar {
  color: rgb(230, 160, 175);
  transform: scale(1.3) translateX(0.75rem);
}
.note-text {
  width: 50%;
  font-weight: 500;
  font-size: calc(0.45rem + 2vmin);
}
.note-link {
  color: rgba(255, 255, 255, 0.5);
  transform: scale(1.2) translateX(-0.5rem);
}
@media screen and (min-width: 1000px) {
  .note-text {
    font-size: calc(0.1rem + 2vmin);
    font-weight: 500;
  }
}
/* Partner */
.partner-wrapper {
  width: 85vw;
  margin: 1rem auto;
}
.partner-title {
  color: rgb(230, 160, 175);
}
.partner-title .t1 {
  font-size: calc(0.8rem + 2vmin);
}
.partner-title .t2 {
  margin: 1rem 0 1rem 0;
  font-size: calc(1.3rem + 2vmin);
  font-weight: 700;
}
.partner-title .t2::after {
  color: rgb(230, 160, 175);
  content: "——";
  font-weight: 200;
}
.partner-avatar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(15, 20, 25);
  width: 80vw;
  height: 12vh;
  padding: 1.5rem 0;
  margin: 1.5rem auto;
  border-radius: 15px;
}
.partner-avatar-container img {
  height: 11vh;
}
